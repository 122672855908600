import { gql, useMutation } from '@apollo/client';
import { isEmpty } from '@lib/fp';
import { useToggle } from '@lib/hooks';
import { useDialog } from '@lib/react-hook-dialog';
import { isPassword } from '@lib/validation';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import DialogForm from 'src/admin/components/DialogForm';

const PasswordInput = ({
  value,
  label,
  onChange,
  disabled,
  helperText,
  error,
}) => {
  const [showPassword, toggleShowPassword] = useToggle(false);

  return (
    <TextField
      fullWidth
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle visibility" onClick={toggleShowPassword} size="large">
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

function DisplayErrors({ errors }) {
  return (
    Array.isArray(errors) &&
    errors.length > 0 &&
    errors.map(text => (
      <Box color="error.main" key={text}>
        {text}
      </Box>
    ))
  );
}

// This validation must be executed for any change in any of the password inputs.
function oldPasswordValidation({ oldPassword, newPassword1, newPassword2 }) {
  // Old password is invalid when is empty and newPassword or repeatNewPassword is set.
  return isEmpty(oldPassword) ? isEmpty(newPassword1 || newPassword2) : true;
}

// This validation must be executed only for new password.
function newPassword1Validation({
  newPassword1,
  setNewPassword1Valid,
  newPassword1Debouncer,
  setNewPassword1Debouncer,
}) {
  const isValid = isEmpty(newPassword1) ? null : isPassword(newPassword1);
  clearTimeout(newPassword1Debouncer);
  if (isEmpty(newPassword1) || isPassword(newPassword1)) {
    // empty or correct password is handled immediatelly
    setNewPassword1Valid(isValid);
    setNewPassword1Debouncer(0);
  } else {
    // non-empty incorrect password uses debouncer to awoid annoying error messages displayed while typing.
    setNewPassword1Valid(null);
    setNewPassword1Debouncer(
      setTimeout(() => setNewPassword1Valid(isValid), 1000),
    );
  }
}

// This validation must be executed for new password and repeat new password.
function newPassword2Validation({
  newPassword1,
  newPassword2,
  setNewPassword2Valid,
  newPassword2Debouncer,
  setNewPassword2Debouncer,
}) {
  const isValid = isEmpty(newPassword2) ? null : newPassword1 === newPassword2;
  clearTimeout(newPassword2Debouncer);
  if (isEmpty(newPassword2) || newPassword1 === newPassword2) {
    // empty or correct password is handled immediatelly
    setNewPassword2Valid(isValid);
    setNewPassword2Debouncer(0);
  } else {
    // non-empty incorrect password uses debouncer to awoid annoying error messages displayed while typing.
    setNewPassword2Valid(null);
    setNewPassword2Debouncer(
      setTimeout(() => setNewPassword2Valid(isValid), 1000),
    );
  }
}

const changePassword = gql`
  mutation WEB_ChangeUserPassword(
    $id: ID!
    $oldPassword: String!
    $newPassword: Password!
  ) {
    changePassword(
      id: $id
      oldPassword: $oldPassword
      newPassword: $newPassword
    )
  }
`;

function ChangePasswordDialog({ user, open, close }) {
  const intl = useIntl();
  const { showAlert } = useDialog();
  const [changePasswordMutation, { loading: working }] = useMutation(
    changePassword,
  );

  const [errors, setErrors] = useState(null);

  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordValid, setOldPasswordValid] = useState(null);

  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword1Valid, setNewPassword1Valid] = useState(null);
  const [newPassword1Debouncer, setNewPassword1Debouncer] = useState(0);

  const [newPassword2, setNewPassword2] = useState('');
  const [newPassword2Valid, setNewPassword2Valid] = useState(null);
  const [newPassword2Debouncer, setNewPassword2Debouncer] = useState(0);

  const onOldPasswordChangeHandler = ({ target: { value } }) => {
    setOldPassword(value);
    const isValid = oldPasswordValidation({
      newPassword1,
      newPassword2,
      oldPassword: value,
    });
    setOldPasswordValid(isValid);
  };

  const onNewPassword1ChangeHandler = ({ target: { value } }) => {
    setNewPassword1(value);
    const isValid = oldPasswordValidation({
      oldPassword,
      newPassword1: value,
      newPassword2,
    });
    setOldPasswordValid(isValid);

    newPassword1Validation({
      newPassword1: value,
      setNewPassword1Valid,
      newPassword1Debouncer,
      setNewPassword1Debouncer,
    });
    newPassword2Validation({
      newPassword1: value,
      newPassword2,
      setNewPassword2Valid,
      newPassword2Debouncer,
      setNewPassword2Debouncer,
    });
  };

  const onNewPassword2ChangeHandler = ({ target: { value } }) => {
    setNewPassword2(value);
    const isValid = oldPasswordValidation({
      oldPassword,
      newPassword1,
      newPassword2: value,
    });
    setOldPasswordValid(isValid);

    newPassword2Validation({
      newPassword2: value,
      newPassword1,
      setNewPassword2Valid,
      newPassword2Debouncer,
      setNewPassword2Debouncer,
    });
  };

  const onSaveHandler = async () => {
    try {
      await changePasswordMutation({
        variables: { id: user.id, oldPassword, newPassword: newPassword1 },
      });
      close();
      showAlert({
        title: (
          <>
            <LockOpenIcon />{' '}
            {intl.formatMessage({ defaultMessage: 'Change password' })}
          </>
        ),
        message: (
          <>
            <CheckIcon />{' '}
            {intl.formatMessage({ defaultMessage: 'The password was changed' })}
          </>
        ),
        confirmText: intl.formatMessage({ defaultMessage: 'Close' }),
      });
    } catch (err) {
      setErrors(err.graphQLErrors.map(error => error.message));
    }
  };

  return (
    <DialogForm
      open={open}
      onClose={close}
      maxWidth="xs"
      title={
        <>
          <LockOpenIcon />{' '}
          {intl.formatMessage({ defaultMessage: 'Change password' })}
        </>
      }
      actions={[
        <Button
          variant="outlined"
          color="primary"
          onClick={close}
          disabled={working}
          key="cancel"
        >
          {intl.formatMessage({ defaultMessage: 'Close' })}
        </Button>,
        <Button
          variant="contained"
          color="primary"
          key="submit"
          disabled={
            working ||
            !(oldPasswordValid && newPassword1Valid && newPassword2Valid)
          }
          onClick={onSaveHandler}
        >
          {intl.formatMessage({ defaultMessage: 'Save' })}
        </Button>,
      ]}
    >
      <DisplayErrors errors={errors} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PasswordInput
            onChange={onOldPasswordChangeHandler}
            disabled={working}
            label={intl.formatMessage({ defaultMessage: 'Old password' })}
            error={oldPasswordValid === false}
            helperText={
              oldPasswordValid === false
                ? intl.formatMessage({
                    defaultMessage: 'Please enter old password',
                  })
                : ' '
            }
          />
        </Grid>

        <Grid item xs={12}>
          <PasswordInput
            onChange={onNewPassword1ChangeHandler}
            disabled={working}
            error={newPassword1Valid === false}
            label={intl.formatMessage({ defaultMessage: 'New password' })}
            helperText={
              newPassword1Valid === false
                ? intl.formatMessage({
                    defaultMessage:
                      'New password must must be at least 10 characters long and must contain at least one lower-case character, upper-case character and number',
                  })
                : ' '
            }
          />
        </Grid>

        <Grid item xs={12}>
          <PasswordInput
            onChange={onNewPassword2ChangeHandler}
            disabled={working}
            error={newPassword2Valid === false}
            label={intl.formatMessage({
              defaultMessage: 'Repeat new password',
            })}
            helperText={
              newPassword2Valid === false
                ? intl.formatMessage({
                    defaultMessage:
                      'Please enter new password again to avoid typos',
                  })
                : ' '
            }
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
}

export default ChangePasswordDialog;
