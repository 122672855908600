import { useAuth, useCheckAccess } from '@lib/authentication';
import { useDisclosure } from '@lib/hooks';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Key from '@mui/icons-material/VpnKey';
import {
  Collapse,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { fromPairs, get } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Link from 'src/components/LinkNext';
import ChangePasswordDialog from './ChangePasswordDialog';

function SecuritySettingsHeader({
  securitySettingsExpanded,
  setSecuritySettingsExpanded,
}) {
  const intl = useIntl();

  return (
    <ListItem
      button
      onClick={() => setSecuritySettingsExpanded(!securitySettingsExpanded)}
    >
      <ListItemText
        secondary={intl.formatMessage({ defaultMessage: 'Security settings' })}
      />
      {securitySettingsExpanded ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
  );
}

function TwoFactorAuthenticationItem() {
  const { user } = useAuth();
  const intl = useIntl();

  const status = user.hasEnabled2FA
    ? intl.formatMessage({ defaultMessage: 'enabled' })
    : intl.formatMessage({ defaultMessage: 'disabled' });

  return (
    <ListItem>
      <ListItemIcon>
        <Key />
      </ListItemIcon>
      <ListItemText
        primary={intl.formatMessage(
          {
            defaultMessage: 'Two factor authentication status: {status}',
          },
          { status },
        )}
      />
    </ListItem>
  );
}

function ChangePasswordItem() {
  const intl = useIntl();
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ListItemButton onClick={onOpen}>
        <ListItemIcon>
          <LockOpenIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ defaultMessage: 'Change password' })}
        />
      </ListItemButton>

      <ChangePasswordDialog user={user} open={isOpen} close={onClose} />
    </>
  );
}

function AdvancedSettingsHeader({
  advancedSettingsExpanded,
  setAdvancedSettingsExpanded,
}) {
  const intl = useIntl();

  return (
    <ListItem
      button
      onClick={() => setAdvancedSettingsExpanded(!advancedSettingsExpanded)}
    >
      <ListItemText
        secondary={intl.formatMessage({ defaultMessage: 'Advanced settings' })}
      />
      {advancedSettingsExpanded ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
  );
}

function StartPageItem({ userSetup, saveUserSetup, disabled }) {
  const intl = useIntl();

  const labels = {
    dashboard: intl.formatMessage({ defaultMessage: 'Dashboard' }),
    recent: intl.formatMessage({ defaultMessage: 'Recent study' }),
  };

  return (
    <ListItemButton disabled={disabled}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <FormControl fullWidth>
        <InputLabel>
          {intl.formatMessage({ defaultMessage: 'Start page' })}
        </InputLabel>
        <Select
          label={intl.formatMessage({ defaultMessage: 'Start page' })}
          value={userSetup.startPage.value.value}
          onChange={e => saveUserSetup('startPage', { value: e.target.value })}
          disabled={disabled}
        >
          {['dashboard', 'recent'].map(key => (
            <MenuItem key={key} value={key}>
              {labels[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ListItemButton>
  );
}

function NotifyNewCandidateItem({ userSetup, saveUserSetup, disabled }) {
  const intl = useIntl();

  const enabled = get(userSetup, 'notifyNewCandidate.value.enabled', false);

  return (
    <ListItemButton disabled={disabled}>
      <ListItemIcon>
        <NotificationsIcon />
      </ListItemIcon>
      <ListItemText
        primary={intl.formatMessage({
          defaultMessage: 'Notify new candidate',
        })}
        secondary={intl.formatMessage({
          defaultMessage: 'Send notification when new candidate received',
        })}
      />
      <Switch
        edge="end"
        checked={enabled && !disabled}
        onChange={() =>
          saveUserSetup('notifyNewCandidate', { enabled: !enabled })
        }
      />
    </ListItemButton>
  );
}

function NotifyNewMessageItem({ userSetup, saveUserSetup, disabled }) {
  const intl = useIntl();

  const enabled = get(userSetup, 'notifyNewMessage.value.enabled', false);

  return (
    <ListItemButton disabled={disabled}>
      <ListItemIcon>
        <NotificationsIcon />
      </ListItemIcon>
      <ListItemText
        primary={intl.formatMessage({ defaultMessage: 'Notify new messages' })}
        secondary={intl.formatMessage({
          defaultMessage:
            'Send notification when new message from candidate received',
        })}
      />
      <Switch
        edge="end"
        checked={enabled && !disabled}
        onChange={() =>
          saveUserSetup('notifyNewMessage', { enabled: !enabled })
        }
      />
    </ListItemButton>
  );
}

function NotifyPendingStatusItem({ userSetup, saveUserSetup, disabled }) {
  const intl = useIntl();

  const value = get(userSetup, 'notifyPendingStatus.value.days', 2);

  return (
    <ListItemButton disabled={disabled}>
      <ListItemIcon>
        <NotificationsIcon />
      </ListItemIcon>
      <ListItemText
        primary={intl.formatMessage({
          defaultMessage: 'Notify pending status',
        })}
        secondary={intl.formatMessage({
          defaultMessage:
            "Send notification when candidate's status not changed for X days",
        })}
      />
      <Select
        value={disabled ? 0 : value}
        onChange={e =>
          saveUserSetup('notifyPendingStatus', { days: e.target.value })
        }
      >
        {[...Array(10).keys()].map(x => (
          <MenuItem key={x} value={x}>
            {x ? String(x) : intl.formatMessage({ defaultMessage: 'Disabled' })}
          </MenuItem>
        ))}
      </Select>
    </ListItemButton>
  );
}

function LogoutItem() {
  const intl = useIntl();

  return (
    <ListItem href={`/logout`} component={Link} color="inherit" button>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText
        primary={intl.formatMessage({ defaultMessage: 'Sign out' })}
      />
    </ListItem>
  );
}

function UserSetupDrawer({ open, close }) {
  const { user, updateUserSettings } = useAuth();
  const { checkAccess } = useCheckAccess();

  const [isSecuritySettingsOpen, setSecuritySettingsExpanded] = useState(false);
  const [isAdvancedSettingsOpen, setAdvancedSettingsExpanded] = useState(false);

  const saveUserSetup = (key, value) => updateUserSettings({ key, value });

  const userSetup = fromPairs(get(user, 'userSetup', []).map(x => [x.key, x]));
  const candidateEnabled = checkAccess({ actions: ['candidate.new:notify'] });
  const messageEnabled = checkAccess({ actions: ['message.new:notify'] });

  return (
    <Drawer anchor="right" open={open} onClose={close}>
      <List style={{ width: '30em' }}>
        <ListItem>
          <ListItemText primary={`${user.firstName} ${user.lastName}`} />
        </ListItem>

        <Divider />

        <SecuritySettingsHeader
          securitySettingsExpanded={isSecuritySettingsOpen}
          setSecuritySettingsExpanded={setSecuritySettingsExpanded}
        />
        <Collapse in={isSecuritySettingsOpen} timeout="auto" unmountOnExit>
          <TwoFactorAuthenticationItem />
          <ChangePasswordItem />
        </Collapse>

        <Divider />

        <AdvancedSettingsHeader
          advancedSettingsExpanded={isAdvancedSettingsOpen}
          setAdvancedSettingsExpanded={setAdvancedSettingsExpanded}
        />
        <Collapse in={isAdvancedSettingsOpen} timeout="auto" unmountOnExit>
          <StartPageItem
            disabled
            userSetup={userSetup}
            saveUserSetup={saveUserSetup}
          />
          <NotifyNewCandidateItem
            disabled={!candidateEnabled}
            userSetup={userSetup}
            saveUserSetup={saveUserSetup}
          />
          <NotifyNewMessageItem
            disabled={!messageEnabled}
            userSetup={userSetup}
            saveUserSetup={saveUserSetup}
          />
          <NotifyPendingStatusItem
            disabled
            userSetup={userSetup}
            saveUserSetup={saveUserSetup}
          />
        </Collapse>

        <Divider />

        <LogoutItem />
      </List>

      <Divider />
    </Drawer>
  );
}

export default UserSetupDrawer;
