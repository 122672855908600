import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

/**
 * @description This is a layout component for constructing more complicated dialogs containing forms.
 *
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.open
 * @param {string} props.maxWidth
 * @param {function} props.onClose
 * @param {object[]} props.actions
 * @param {object} props.children
 * @param {boolean=} props.noContentPadding - remove all padding around content
 * @param {boolean=} props.scrollContent - allow content to scroll, keeping actions visible
 * @param {boolean=} props.contentDividers - add dividers above and below the content
 */
function DialogForm({
  open,
  onClose,
  title,
  children,
  actions,
  maxWidth = 'md',
  noContentPadding = false,
  scrollContent = false,
  contentDividers = false,
}) {
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={maxWidth}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          border: '1px solid #d9d9d9',
          boxShadow: '0px 6px 48px 16px rgba(0, 0, 0, 0.12)',
          // Disable scrolling the whole dialog (including action buttons and title) when the content is scrollable.
          ...(scrollContent && { overflow: 'initial' }),
        },
      }}
    >
      <CustomDialogTitle>
        {title}
        {onClose ? (
          <CustomIconButton aria-label="close" onClick={onClose} size="large">
            <CloseIcon />
          </CustomIconButton>
        ) : null}
      </CustomDialogTitle>

      {contentDividers && <Divider />}

      <DialogContent
        sx={{
          margin: 0,
          py: 0,
          px: 3,
          ...(noContentPadding && { p: 0 }),
          ...(!scrollContent && { overflowY: 'initial' }),
        }}
      >
        {children}
      </DialogContent>

      {contentDividers && <Divider />}

      <DialogActions
        sx={{ margin: 0, p: 3, pt: 6, justifyContent: 'flex-end' }}
      >
        <Stack direction="row" spacing={3}>
          {actions}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  typography: 'subtitle1',
  padding: theme.spacing(3),
}));

const CustomIconButton = styled(IconButton)(() => ({
  padding: 0,
  position: 'absolute',
  right: 24,
  top: 24,
  color: 'grey.500',
}));

export default DialogForm;
