/**
 * @private
 *
 * Define what a valid password is.
 *
 * @param {string} value
 * @returns {Boolean}
 */
export function isPassword(x) {
  return passwordRegexp.test(x);
}

/**
 * https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
 */
const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{10,}$/;
