import { useConfig } from '@lib/config';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

function Version(props) {
  const intl = useIntl();
  const { systemVersion } = useConfig();

  return (
    <Box {...props}>
      {intl.formatMessage(
        { defaultMessage: 'Version: {systemVersion}' },
        { systemVersion },
      )}
    </Box>
  );
}

export default Version;
