import { useCheckAccess } from '@lib/authentication';
import { useDisclosure } from '@lib/hooks';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import LinkNext from 'src/components/LinkNext';
import HelpMenu from './HelpMenu';
import UserSetupDrawer from './UserSetupDrawer';

function AppHeader({ studyId }) {
  const intl = useIntl();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { checkAccess } = useCheckAccess(studyId);

  return (
    <>
      <Box mr={3}>
        {checkAccess({ actions: ['user:block'] }) ? (
          <CustomIconButton
            href="/admin/users"
            label={intl.formatMessage({
              defaultMessage: 'User Administration',
            })}
          >
            <SettingsIcon />
          </CustomIconButton>
        ) : null}

        <HelpMenu ariaLabel="Help" />

        <IconButton
          sx={{
            color: 'grey.600',
            backgroundColor: 'transparent',
            opacity: 0.5,
          }}
          aria-label={intl.formatMessage({
            defaultMessage: 'User account',
          })}
          onClick={onOpen}
          size="large"
        >
          <AccountCircleIcon />
        </IconButton>
      </Box>

      <UserSetupDrawer open={isOpen} close={onClose} />
    </>
  );
}

const CustomIconButton = ({ href, children, label }) => {
  return (
    <IconButton
      sx={{
        color: 'grey.600',
        backgroundColor: 'transparent',
        opacity: 0.5,
      }}
      component={LinkNext}
      href={href}
      aria-label={label}
      size="large"
    >
      {children}
    </IconButton>
  );
};

export default AppHeader;
