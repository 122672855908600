import { gql, useMutation, useQuery } from '@apollo/client';
import { useAuth } from '@lib/authentication';
import { logoutPathFrom } from '@lib/routing-helpers';
import { useRouter as useNextRouter } from 'next/router';
import { useEffect, useState } from 'react';
import SessionExpiration from 'src/components/SessionExpiration';

const LAST_ERROR = gql`
  query {
    lastError @client
  }
`;

const CLEAR_LAST_ERROR = gql`
  mutation {
    clearLastError @client
  }
`;

function LoggedInGuard({ children }) {
  const { loading, isLoggedIn, logout } = useAuth();
  const { data } = useQuery(LAST_ERROR);
  const [clearLastError] = useMutation(CLEAR_LAST_ERROR);
  const [loginFromExpiration, setLoginFromExpiration] = useState(isLoggedIn);
  const nextRouter = useNextRouter();

  const localLogout = () => {
    logout();
    setTimeout(() => {
      nextRouter.push(logoutPathFrom(nextRouter.asPath));
      setLoginFromExpiration(true);
    }, 16);
  };

  useEffect(() => {
    if (!isLoggedIn && !loading && !loginFromExpiration) {
      nextRouter.push(logoutPathFrom(nextRouter.asPath));
      setLoginFromExpiration(true);
    }
  }, [isLoggedIn, loading, loginFromExpiration, nextRouter]);

  useEffect(() => {
    if (data.lastError) clearLastError();
  }, [clearLastError, data.lastError]);

  if (!isLoggedIn || loading) return null;
  const notifyAt = 1000 * 60 * 10;
  return (
    <>
      <SessionExpiration
        expiration={notifyAt * 2}
        callback={localLogout}
        notifyBreakpoint={notifyAt}
      />
      {children}
    </>
  );
}

export default LoggedInGuard;
