import { useQuery } from '@apollo/client';

const dataField = data => {
  return data[Object.keys(data)[0]];
};

const isDataNull = data => {
  return dataField(data) === null;
};

const isDataEmptyArray = data => {
  const field = dataField(data);
  return Array.isArray(field) && field.length === 0;
};

const isDataEmpty = data => {
  return isDataNull(data) || isDataEmptyArray(data);
};

/**
 * Creates a Cell out of a GraphQL query and components that track to its lifecycle.
 */
export function createCell({
  QUERY,
  beforeQuery = props => ({
    variables: props,
    /**
     * We're duplicating these props here due to a suspected bug in Apollo Client v3.5.4
     * (it doesn't seem to be respecting `defaultOptions` in `ApolloProvider`.)
     *
     * @see {@link https://github.com/apollographql/apollo-client/issues/9105}
     */
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  }),
  afterQuery = data => ({ ...data }),
  isEmpty = isDataEmpty,
  Loading = () => <>Loading...</>,
  Failure = null,
  Empty = null,
  Success,
  displayName = 'Cell',
}) {
  /**
   * If we're prerendering, render the Cell's Loading component and exit early.
   */
  if (typeof window === 'undefined') {
    /**
     * Apollo Client's props aren't available here, so 'any'.
     */
    // Never runs in the client so lint rule is meaningless
    // eslint-disable-next-line react/display-name
    return props => <Loading {...props} />;
  }

  function NamedCell(props) {
    /**
     * Right now, Cells don't render `children`.
     */
    const { children: _, ...variables } = props;

    const options = beforeQuery(variables);

    const { error, loading, data, ...queryRest } = useQuery(
      typeof QUERY === 'function' ? QUERY(options) : QUERY,
      options,
    );

    const commonProps = {
      updating: loading,
      ...queryRest,
      ...props,
    };

    if (error) {
      if (Failure) {
        return (
          <Failure
            error={error}
            errorCode={error.graphQLErrors?.[0]?.extensions?.['code']}
            {...commonProps}
          />
        );
      } else {
        throw error;
      }
    } else if (data) {
      const afterQueryData = afterQuery(data);

      if (isEmpty(data, { isDataEmpty }) && Empty) {
        return <Empty {...{ ...afterQueryData, ...commonProps }} />;
      } else {
        return <Success {...{ ...afterQueryData, ...commonProps }} />;
      }
    } else if (loading) {
      return <Loading {...{ ...queryRest, ...props }} />;
    } else {
      console.warn(
        `If you're using Apollo Client, check for its debug logs here in the console, which may help explain the error.`,
      );
      throw new Error(
        'Cannot render Cell: reached an unexpected state where the query succeeded but `data` is `null`. If this happened in Storybook, your query could be missing fields; otherwise this is most likely a GraphQL caching bug. Note that adding an `id` field to all the fields on your query may fix the issue.',
      );
    }
  }

  NamedCell.displayName = displayName;

  return NamedCell;
}
