import { Fragment, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDisclosure, useInterval, useThrottle } from '../../utils/hooks';
import { useDialog } from '../../utils/react-hook-dialog';
/**
 * @param {Object} props
 * @param {number} props.expiration - ms until expiration
 * @param {number} props.notifyBreakpoint - ms before expiration that the user should be notified about pending expiration
 * @param {function} props.callback - function invoked when timer expires or user want to be logged out
 */
const SessionExpiration = ({
  expiration,
  callback = noop,
  notifyBreakpoint,
}) => {
  const intl = useIntl();
  const { getConfirmation, resetDialog } = useDialog();
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  const ref = useRef(Date.now());

  const resetTime = () => {
    ref.current = Date.now();
    onClose();
  };

  const safeResetTime = useThrottle(() => {
    if (isOpen) return;

    resetTime();
  }, secondInMs);

  useInterval(() => {
    if (!isOpen && differenceInMs(ref.current) > notifyBreakpoint) {
      onOpen();
    }
    if (differenceInMs(ref.current) >= expiration && !!callback) {
      callback();
      resetDialog();
    }
  }, secondInMs);

  useEffect(() => {
    window.addEventListener('click', safeResetTime);
    window.addEventListener('scroll', safeResetTime);
    window.addEventListener('keydown', safeResetTime);

    return () => {
      window.removeEventListener('click', safeResetTime);
      window.removeEventListener('scroll', safeResetTime);
      window.removeEventListener('keydown', safeResetTime);
    };
  }, [safeResetTime]);

  useEffect(() => {
    if (!isOpen) return;

    startInactiveWarning();
  }, [isOpen]);

  const startInactiveWarning = async () => {
    const result = await getConfirmation({
      title: intl.formatMessage({
        defaultMessage: 'Session expiration reminder',
      }),
      message: intl.formatMessage(
        { defaultMessage: 'You will be logged out in {x}' },
        { x: <Countdown timer={expiration - notifyBreakpoint} /> },
      ),
      confirmText: intl.formatMessage({ defaultMessage: 'Stay logged in' }),
      cancelText: intl.formatMessage({ defaultMessage: 'Log out' }),
      blurBackground: true,
      confirmOnClose: true,
    });

    if (result) {
      resetTime();
    } else {
      callback();
    }
  };

  return <Fragment />;
};

const differenceInMs = ms => {
  return Date.now() - ms;
};

const secondInMs = 1000;
const noop = () => {};

const Countdown = ({ timer }) => {
  const [counter, setCounter] = useState(timer);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1000), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return <>{millisToMinutesAndSeconds(counter)}</>;
};

function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

export default SessionExpiration;
