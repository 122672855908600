import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Help from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Link from 'src/components/LinkNext';
import Version from 'src/components/Version';
import { SUPPORT_EMAIL, SUPPORT_URL } from 'src/environment';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[600],
    backgroundColor: 'transparent',
    opacity: 0.5,
  },
  menuItem: {
    color: theme.palette.grey[900],
  },
}));

const HelpMenu = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className={classes.root}
        aria-label={intl.formatMessage({ defaultMessage: 'Help menu' })}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <Help />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          className={classes.menuItem}
          component={Link}
          href={SUPPORT_URL}
        >
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>

          <ListItemText>
            {intl.formatMessage({
              defaultMessage: 'Honey support site',
            })}
          </ListItemText>
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          component={Link}
          href="/learning"
        >
          <ListItemIcon>
            <OndemandVideoIcon />
          </ListItemIcon>
          <ListItemText>
            {intl.formatMessage({ defaultMessage: 'Learning videos' })}
          </ListItemText>
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          component={Link}
          href={`mailto:${SUPPORT_EMAIL}`}
        >
          <ListItemIcon>
            <ContactSupportIcon />
          </ListItemIcon>
          <ListItemText>
            {intl.formatMessage({ defaultMessage: 'Contact support' })}
          </ListItemText>
        </MenuItem>

        <MenuItem disabled>
          <ListItemText
            primaryTypographyProps={{ variant: 'caption', color: 'black' }}
          >
            <Version />
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default HelpMenu;
