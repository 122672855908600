export const SURVEY_URL = process.env.NEXT_PUBLIC_SURVEY_URL;

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const VERSION = process.env.NEXT_PUBLIC_VERSION || 'latest';
export const RELEASE_STAGE = process.env.NEXT_PUBLIC_RELEASE_STAGE;

export const SUPPORT_URL = 'https://support.trialbee.com';
export const SUPPORT_EMAIL = 'support@trialbee.com';

export const FRESHDESK_ID = process.env.FRESHDESK_ID;
export const FRESHDESK_ID_ADMIN = process.env.FRESHDESK_ID_ADMIN;

export const IS_PROD = process.env.NODE_ENV === 'production';

export const THEME = process.env.THEME;
